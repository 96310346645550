import { makeStyles } from "@mui/styles";

const ThanYouStyles = makeStyles((theme) => ({
  hoverDiv: {
    border: "2px solid red",
  },
  headingTestStyle: {
    fontWeight: "500 !important",
    fontSize: "24px !important",
    background: "#fff !important",
    width: "100% !important",
    paddingTop: "20px !important",
    paddingBottom: "20px !important",
    borderRadius: "10px 10px 0px 0px !important",
    fontFamily: "Roboto !important",
    borderBottom: "1px solid #EAECF0 !important",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px !important",
    },
  },
}));

export default ThanYouStyles;
