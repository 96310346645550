import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import Signup from './components/Signup';
import VerifyEmail from './components/VerifyEmail';
import ThankYou from './components/ThankYou';
import AlreadySignup from './components/AlreadySignup';
import CustomizedSnackbars from './components/CustomizedSnackbars';
import { useState } from 'react';
import PrivateRoute from './components/PrivateRoute.js';
import { gapi } from "gapi-script";
const theme = createTheme();

function App() {
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    message: '',
    severity: ''
  })

  gapi.load("client:auth2", () => {
    gapi.client.init({
      clientId:
      '797641613328-cqabo5hjt5qk6jlq6p7frf380b161q0q.apps.googleusercontent.com',
      plugin_name: "chat",
    });
  });

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route exact path="/" element={<Signup setSnackbarState={setSnackbarState} />} />
          <Route exact path="/verify-email" element={
            <PrivateRoute>
              <VerifyEmail setSnackbarState={setSnackbarState} />
            </PrivateRoute>
          }
          />
          <Route exact path="/thank-you" element={<ThankYou setSnackbarState={setSnackbarState} />} />
          <Route exact path="/signup" element={<AlreadySignup setSnackbarState={setSnackbarState} />} />
        </Routes>
      </Router>
      <CustomizedSnackbars snackbarState={snackbarState} setSnackbarState={setSnackbarState} />
    </ThemeProvider>
  );
}

export default App;
