import React, { useEffect, useState } from "react";
import {
  Button,
  FormControl,
  Grid,
  Typography,
  CircularProgress,
  Box,
  Stack,
} from "@mui/material";
import { useLocation} from "react-router-dom";
import Logo from "../../assets/images/black-logo-with-text.svg";
import { axiosPost } from "../../helpers/Axios";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";

import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import ContentCopyTwoToneIcon from "@mui/icons-material/ContentCopyTwoTone";
import { useClipboardCopy } from "../../hooks/useClipboardCopy";
import ThanYouStyles from "./ThanYouStyles";
import { Helmet } from "react-helmet";

const ThankYou = (props) => {
  const classes = ThanYouStyles();
  const [value, setValue] = useState(null);
  const [header, setHeader] = useState(null);
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(true);
  const { copy, isCopied } = useClipboardCopy();
  // const navigate = useNavigate();
  const search = useLocation().search;

  useEffect(() => {
    const validationCode = new URLSearchParams(search).get("validationCode");
    const validationKey = new URLSearchParams(search).get("validationKey");
    const token = new URLSearchParams(search).get("token");
    const userKey = localStorage.getItem("userKey");
    if (validationCode && validationKey && token) {
      axiosPost("api/verify-email", { validationCode, validationKey, token })
        .then((resp) => {
          if (resp && resp.code === "success") {
            setValue(resp.userKey);
            setHeader("Thank You");
            setMessage(
              "Thank you for verifying your account. <br />Here is your API Key"
            );
            setLoading(false);
          }
          else if (resp && resp.code === 'not_valid') {
            setHeader('Invalid Link')
            setMessage(`Please, check your email for valid link.`)
            setLoading(false)
          } else if (resp && resp.code === 'token_expired') {
            setHeader('Expired Link')
            setMessage(`Link is expired. Please, Signup again !!`)
            setLoading(false)
          } else {
            setHeader("Server Error");
            setMessage(`Please, try again !!`);
            setLoading(false);
          }
        })
        .catch((err) => {
          setHeader("Server Error");
          setMessage(`Please, try again !!`);
          setLoading(false);
        });
    } else if (userKey) {
      setValue(userKey);
      setHeader("Thank You");
      setMessage(
        "Thank you for verifying your account. <br />Here is your API Key"
      );
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [search]);

  const onClickSubmit = () => {
    // navigate('https://expand.network/')
    window.location.replace("https://docs.expand.network/getting-started");
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Expand Network - Thank You</title>
      </Helmet>
      <Grid
        container
        style={{
          height: "auto",
          backgroundColor: "linear-gradient(270deg, #338CF5 0%, #D0E6FF 51.01%, #338CF5 100%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={10}
          style={{ textAlign: "center", marginTop: 50 }}
        >
          <Typography
            variant="h4"
            sx={{
              color: "#fff",
              fontWeight: "bold",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
            onClick={() => window.location.replace("https://expand.network/")}
          >
            <img src={Logo} alt="logo" width="187.5px" height="40px" />
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            padding: "0px 10px",
            minHeight: window.innerHeight - 170,
          }}
        >
          {loading ? (
            <Stack
              sx={{ margin: "auto", marginTop: "100px" }}
              spacing={2}
              direction="row"
            >
              <CircularProgress sx={{ color: "#fff" }} size={100} />
            </Stack>
          ) : (
            <Grid
              item
              xs={12}
              md={12}
              lg={12}
              style={{
                textAlign: "center",
                marginTop: 30,
                borderRadius: 10,
                background: "#fff",
                maxWidth: "498px",
              }}
            >
              <Typography className={classes.headingTestStyle}>
                {header}
              </Typography>
              {/* <Typography
              className={classes.headingTestStyle}
              variant="h5"
            >
              {header}
            </Typography> */}
              <Box
                style={{
                  padding: "20px 32px",
                  background: "#F9F9FB",
                  borderRadius: "10px",
                  borderTopLeftRadius: "0px",
                  borderTopRightRadius: "0px",
                  paddingBottom: "32px",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontWeight: 400,
                    color: message.includes("Thank") ? "#000" : 'red',
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "left",
                    justifyContent: "left",
                  }}
                >
                  {message.includes("Thank") ? (
                    <>
                      <p>
                        Here is your API Key which you can now use to query and
                        transact on all major Layer 1 & 2 chains, and DeFi
                        Protocols through our single secure SDK
                        <br />
                        <br />
                        You have also received your API Key in a Welcome Email
                        which provides you with everything you need to get
                        started.
                        <br />
                        <br />
                        Our support team is here to help so please contact us any
                        time - details in your Welcome Email and on our website.
                      </p>
                    </>
                  ) : (
                    message
                  )}
                </Typography>
                {/* <br /> */}
                {value && (
                  <>
                    <FormControl
                      margin="normal"
                      required
                      fullWidth
                      style={{ justifyContent: "left" }}
                    >
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type="text"
                        value={value}
                        size="large"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="api user key"
                              onClick={() => copy(value)}
                              // onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {isCopied ? (
                                <ContentCopyTwoToneIcon />
                              ) : (
                                <ContentCopyOutlinedIcon
                                  sx={{ color: "#000" }}
                                />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                    </FormControl>
                    <br />
                    <br />
                    <Button
                      type="submit"
                      fullWidth
                      variant="contained"
                      size="large"
                      style={{
                        padding: "10px",
                        backgroundColor: "#338cf5",
                        color: "#fff",
                        textTransform: "inherit",
                        fontSize: "16px",
                        fontWeight: "500",
                        height: "48px",
                      }}
                      onClick={onClickSubmit}
                    >
                      <Box>Continue</Box>
                    </Button>
                  </>
                )}
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100vw",
            alignItems: "center",
            height: 80,
          }}
        >
          <Typography
            variant="p"
            style={{ color: "#000", fontSize: 12, fontWeight: 500 }}
          >
            © 2022 expand.network. All Rights Reserved.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};

export default ThankYou;
