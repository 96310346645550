import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ redirectPath = '/', children }) => {
  const email = localStorage.getItem('email')
  if (!email) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
}


export default PrivateRoute;
