import React, { useState } from "react";

export const useClipboardCopy = (text2) => {
   const [isCopied, setCopy] = useState(false);
   const copy = (text) => {
      console.log("Text == ", text)
      navigator.clipboard
         .writeText(text)
         .then(() => setCopy(true))
         .then(() =>
            setTimeout(() => {
               setCopy(false);
            }, 3000)
         );
   };
   return { copy, isCopied }
};
